import {
    Avatar,
    Box,
    Divider,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useInput } from "../../context-utils/InputContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import AddSales from "./AddSales";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

type User = {
    id: string;
    displayName: string;
    companyIds?: string[];
};

type Company = {
    id: string;
    name: string;
};

export default function SalesPage(): React.JSX.Element {
    const [currentCompanyId, setCurrentCompanyId] = useState<string>("");
    const [companies, setCompanies] = useState<Company[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [columnWidths, setColumnWidths] = useState<number[]>([30, 50, 20]);
    const [columnDisplay, setColumnDisplay] = useState<number[]>([1, 1, 1]);
    const [currentColumn, setCurrentColumn] = useState<number>(0);
    const [currentSelection, setCurrentSelection] = useState<string>("VIEW_SALES");

    const { user } = useContext(AuthContext) as { user: User };
    const input = useInput();
    const colors = useColors();
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const windowSize = useWindowSize();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (isMobile) {
            switch (currentColumn) {
                case 0: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
                case 1: {
                    setColumnDisplay([0, 1, 0]);
                    setColumnWidths([0, 100, 0]);
                    break;
                }
                case 2: {
                    setColumnDisplay([0, 0, 1]);
                    setColumnWidths([0, 0, 100]);
                    break;
                }
                default: {
                    setColumnDisplay([1, 0, 0]);
                    setColumnWidths([100, 0, 0]);
                    break;
                }
            }
        } else {
            setColumnDisplay([1, 1, 1]);
            setColumnWidths([30, 50, 20]);
        }
    }, [isMobile, currentColumn]);

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "companies");
        const q = query(collectionRef, where("admins", "array-contains", user.id));

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            let companies = mapSnapshotTS<Company>(querySnapshot);

            const userCompanyIds = user.companyIds || [];

            const userCompanies = await Promise.all(
                userCompanyIds.map(async (companyId) => {
                    const docRef = doc(db, "companies", companyId);
                    const snapshot = await getDoc(docRef);
                    const company = mapDocSnapshot(snapshot);
                    return company;
                })
            );

            companies = [...companies, ...userCompanies];

            // Remove duplicates based on id
            companies = companies.filter(
                (company, index, self) => index === self.findIndex((t) => t.id === company.id)
            );

            // order by name
            companies = companies.sort((a, b) => a.name.localeCompare(b.name));

            setCompanies(companies);
        });

        return unsubscribe;
    }, [user]);

    const handleChangeCompany = async (e: any) => {
        if (e.target.value === "addCompany") {
            const response = await input("Add new company", "Enter the company name", "Company name", "");
            if (response) {
                // add new company
                const collectionRef = collection(db, "companies");
                const { id } = await addDoc(collectionRef, {
                    name: response,
                    admins: [user.id],
                    created: new Date(),
                    createdBy: user.id,
                });
                setCurrentCompanyId(id);
            }
        } else {
            setCurrentCompanyId(e.target.value as string);
        }
    };

    const handleChangeSelection = (e: any) => {
        setCurrentSelection(e.target.value);
        setCurrentColumn(1);
    };

    const getComponent = () => {
        switch (currentSelection) {
            case "ADD_SALE": {
                return <AddSales companyId={currentCompanyId} />;
            }
            case "VIEW_SALES": {
                return <div>View Sales</div>;
            }
            default: {
                return <div>View Sales</div>;
            }
        }
    };

    return (
        <Box display="flex" width={"100vw"} height={`calc(100vh - 55px)`}>
            {/* First column */}
            <Box
                display={columnDisplay[0] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[0] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    p={1}
                    py={2}
                    width={"100%"}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <FormControl fullWidth>
                        <InputLabel>Company</InputLabel>
                        <Select value={currentCompanyId} label="Company" onChange={handleChangeCompany}>
                            {companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem value={"addCompany"}>
                                <Icon sx={{ marginRight: "8px" }}>add</Icon>
                                Add New Company
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        {isMobile && (
                            <IconButton onClick={() => setCurrentColumn(1)}>
                                <Icon>settings</Icon>
                            </IconButton>
                        )}
                    </Box>
                </Box>
                <Box display="flex" flexDirection={"column"} p={1}>
                    <Select
                        value={currentSelection}
                        onChange={handleChangeSelection}
                        disabled={!currentCompanyId}
                    >
                        <MenuItem value={"ADD_SALE"}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Icon>add</Icon>
                                <Typography>Add Sale</Typography>
                            </Box>
                        </MenuItem>
                        <MenuItem value={"VIEW_SALES"}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Icon>view_stream</Icon>
                                <Typography>View Sales</Typography>
                            </Box>
                        </MenuItem>
                    </Select>
                </Box>
            </Box>

            {/* Second column */}
            <Box
                display={columnDisplay[1] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[1] + "%"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    height={"86px"}
                    sx={{ borderBottom: "1px solid grey" }}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(0)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Avatar />
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    width="100%"
                    height="100%"
                    sx={{
                        backgroundSize: "cover", // to cover the entire Box area
                        backgroundPosition: "center",
                        overflowY: "auto",
                    }}
                >
                    {getComponent()}
                </Box>
            </Box>

            {/* Third column */}
            <Box
                display={columnDisplay[2] ? "flex" : "none"}
                flexDirection={"column"}
                width={columnWidths[2] + "%"}
                height={"100%"}
            >
                <Box
                    display="flex"
                    gap={1}
                    alignItems={"center"}
                    sx={{ borderBottom: "1px solid grey" }}
                    p={1}
                >
                    {isMobile && (
                        <IconButton onClick={() => setCurrentColumn(0)}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    )}
                    <Typography>Settings</Typography>
                </Box>
            </Box>
        </Box>
    );
}
