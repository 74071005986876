import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, Grid, IconButton, Paper } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { Container } from "@mui/system";
import { collection, getDocs, query } from "firebase/firestore";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useMemo } from "react";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name, Title } from "../../themes/themes";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { AuthContext } from "./../../context/AuthContext";
import formatDate from "./../../utils-functions/formatDate";
import deleteClient from "./deleteClient";
import generateSecret from "./generateSecret";
import getState from "./getState";

export default function BackendDashboard() {
    const [whatsappClients, setWhatsappClients] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState("");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const loadData = async () => {
            const collectionRef = collection(db, "whatsappClients");
            const q = query(collectionRef, orderBy("date", "desc"));

            setLoading(true);
            const snapshot = await getDocs(q);
            const clients = mapSnapshot(snapshot);
            setWhatsappClients(clients);
            setLoading(false);
        };

        if (user.role === "Super Admin") {
            loadData();
        }
    }, [user]);

    const numberOfReadyClients = useMemo(() => {
        return whatsappClients.filter((client) => client.status === "ready").length;
    }, [whatsappClients]);

    const handleClickStatus = (status) => {
        setStatus(status);
    };

    const filteredClients = useMemo(() => {
        if (!status) return whatsappClients;
        if (status === "all") return whatsappClients;
        return whatsappClients.filter((client) => client.status === status);
    }, [status, whatsappClients]);

    // Main Layout Component
    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox gap="8px">
                    <WhatsAppIcon sx={{ color: green[500] }} fontSize="large" />
                    <Title>Clients Dashboard</Title>
                </GridFlexBox>
                <Loading loading={loading} />
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                <Grid item xs={12}>
                    <Box display="flex" gap="8px">
                        <Button onClick={() => handleClickStatus("ready")}>Ready</Button>
                        <Button onClick={() => handleClickStatus("disconnected")}>Disconnected</Button>
                        <Button onClick={() => handleClickStatus("all")}>All</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ClientIdsComponent />
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}></Grid>
            </Grid>
        </Container>
    );

    function ClientIdsComponent() {
        return (
            <Grid item xs={12} display="flex" flexDirection={"column"}>
                <Paper sx={{ padding: "8px" }}>
                    <Box display="flex" gap="8px" justifyContent={"space-between"}>
                        <Name>clientIds:</Name>
                        <Name>Number of active clients: {numberOfReadyClients}</Name>
                    </Box>
                    <GridDivider />
                    <Grid
                        container
                        display="flex"
                        spacing={1}
                        sx={{ overflowY: "scroll", height: "75vh", paddingRight: "8px" }}
                    >
                        {filteredClients.map((client, i) => (
                            <ClientStatus key={client.id} client={client} i={i} />
                        ))}
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

function ClientStatus({ client, i }) {
    const [status, setStatus] = React.useState("");
    const [size, setSize] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            const secretCounter = client.secretCounter || 1;
            const secret = generateSecret(client.id, secretCounter);
            await getState(client.id, setStatus, secret, setSize);
        };

        loadData();
    }, [client]);

    const confirmation = useConfirmation();

    const handleDisconnect = async (client) => {
        console.log(client);
        const response = await confirmation("Disconnect client", "Confirm?");
        if (response) {
            const secretCounter = client.secretCounter || 1;
            const secret = generateSecret(client.id, secretCounter);
            await deleteClient(client.id, secret);
            console.log(`${client.id} disconnected`);
        }
    };

    return (
        <React.Fragment>
            <Grid item display="flex" xs={6}>
                <Box display={"flex"} gap="8px">
                    <Name>{i + 1}</Name>
                    <Name nowrap>{client.id}</Name>
                </Box>
            </Grid>
            <Grid display={"flex"} item justifyContent={"flex-end"} xs={6}>
                <Name>{formatDate(convertDate(client.date))}</Name>
            </Grid>
            <Grid item display="flex" xs={6}>
                <Name>{client.instanceName}</Name>
            </Grid>
            <Grid display={"flex"} item justifyContent={"flex-end"} xs={6}>
                <Box display={"flex"} gap="8px">
                    <Name nowrap color={statusColor(status)}>
                        {status || "loading..."}
                    </Name>
                </Box>
            </Grid>
            <Grid display={"flex"} item justifyContent={"flex-end"} xs={12}>
                <Box display="flex" gap="8px">
                    <Name>Firestore status: </Name>
                    <Name nowrap color={statusColor(status)}>{`${client.status || ""}`}</Name>
                </Box>
            </Grid>
            <Grid display={"flex"} item justifyContent={"flex-end"} xs={12}>
                <Box display="flex" gap="8px">
                    <Name>Dir Size: </Name>
                    <Name nowrap color={statusColor(status)}>{`${size || ""}`}</Name>
                </Box>
            </Grid>
            <Grid display={"flex"} item justifyContent={"flex-end"} xs={12}>
                <IconButton size="small" onClick={() => handleDisconnect(client)}>
                    <Name>Disconnect</Name>
                </IconButton>
            </Grid>
            <GridDivider />
        </React.Fragment>
    );
}

const statusColor = (status) => {
    switch (status) {
        case "ready":
            return green[500];
        case "":
            return blue[500];
        case "disconnected":
            return red[500];
        default:
            return "";
    }
};
