import { Box, Button, Divider, Icon, IconButton, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { addDoc, collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useInput } from "../../context-utils/InputContext";
import useList from "../../context-utils/ListContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useChooseFriend from "../../hooks/useChooseFriend";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshotTS from "../../utils-functions/mapSnapshotTS";

export type CustomerProduct = {
    id: string;
    name: string;
};

export default function AddSales({ companyId }: { companyId: string | null }) {
    const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [products, setProducts] = useState<CustomerProduct[]>([]);
    const [currentProductId, setCurrentProductId] = useState<string | null>(null);
    const [currentProduct, setCurrentProduct] = useState<CustomerProduct | null>(null);
    const [agentIds, setAgentIds] = useState<string[]>([]);
    const [agentNames, setAgentNames] = useState<string[]>([]);
    const [percentages, setPercentages] = useState<string[]>([]);
    const [customValues, setCustomValues] = useState<string[]>([]);
    const [customRemarks, setCustomRemarks] = useState<string[]>([]);
    const [remark, setRemark] = useState<string>("");
    const [value, setValue] = useState<string>("");

    const { user } = useContext(AuthContext);

    const windowSize = useWindowSize();
    const list = useList();
    const input = useInput();
    const friendSelector = useChooseFriend();

    const isMobile = useMemo(() => {
        if (windowSize.width) {
            return windowSize.width < 600;
        } else {
            return false;
        }
    }, [windowSize.width]);

    useEffect(() => {
        if (companyId) {
            // fetch products
            const collectionRef = collection(db, "customerProducts");
            const q = query(collectionRef, where("companyId", "==", companyId));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const products = mapSnapshotTS<CustomerProduct>(querySnapshot);

                setProducts(products);
            });

            return unsubscribe;
        }
    }, [companyId]);

    useEffect(() => {
        if (!currentProductId) {
            setCurrentProduct(null);
            return;
        }

        const docRef = doc(db, "customerProducts", currentProductId);
        const unsubscribe = onSnapshot(docRef, (doc: any) => {
            const product = mapDocSnapshot(doc);
            setCurrentProduct(product);
        });

        return unsubscribe;
    }, [currentProductId]);

    const handleSelectProduct = async () => {
        const response = await list(products, "Select Product", "name", "inventory", true, true);

        if (!response) return;

        if (response === "addAccount") {
            const productName = await input("Add new product", "Enter the product name", "Product name", "");
            if (productName) {
                // add new product
                const collectionRef = collection(db, "customerProducts");
                const { id } = await addDoc(collectionRef, {
                    name: productName,
                    companyId,
                    date: new Date(),
                    createdBy: user.id,
                });
                setCurrentProductId(id);
                toast.success("Product added successfully");
            }
        } else {
            setCurrentProductId(response.id);
        }
    };

    const handleAddAgent = async () => {
        //
        const response = await friendSelector();

        if (response) {
            const agentId = response.id;
            const agentName = response.displayName;
            setAgentIds([...agentIds, agentId]);
            setAgentNames([...agentNames, agentName]);
        }
    };

    const handleRemoveAgent = (index: number) => {
        const newAgentIds = agentIds.filter((_, i) => i !== index);
        const newAgentNames = agentNames.filter((_, i) => i !== index);
        const newPercentages = percentages.filter((_, i) => i !== index);
        const newCustomValues = customValues.filter((_, i) => i !== index);
        const newRemarks = customRemarks.filter((_, i) => i !== index);

        setAgentIds(newAgentIds);
        setAgentNames(newAgentNames);
        setPercentages(newPercentages);
        setCustomValues(newCustomValues);
        setCustomRemarks(newRemarks);
    };

    if (!companyId) {
        return (
            <Box p={1}>
                <Typography>Select a company to add sales</Typography>
            </Box>
        );
    }

    return (
        <Box p={1} width={isMobile ? "100%" : "600px"} display="flex" flexDirection={"column"} gap={1}>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography>Date: </Typography>
                <DatePicker value={date} onChange={(newValue) => setDate(newValue)} sx={{ width: "300px" }} />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography>Product: </Typography>
                {currentProduct ? (
                    <Box display="flex" gap={1} alignItems={"center"}>
                        <Typography>{currentProduct.name}</Typography>
                        <IconButton size="small" onClick={() => setCurrentProductId("")}>
                            <Icon>clear</Icon>
                        </IconButton>
                    </Box>
                ) : (
                    <Button variant="contained" size="small" onClick={handleSelectProduct}>
                        Select Product
                    </Button>
                )}
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography>Value: </Typography>
                <TextField
                    size="small"
                    value={value}
                    variant="outlined"
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    sx={{ width: "300px" }}
                />
            </Box>
            <Box width="100%" my={1}>
                <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Button variant="contained" size="small" onClick={handleAddAgent}>
                    Add Agent
                </Button>
            </Box>
            <Box display="flex" flexDirection={"column"}>
                {agentNames.map((agentName, index) => (
                    <Box
                        display="flex"
                        gap={1}
                        alignItems={"center"}
                        key={index}
                        justifyContent="space-between"
                        mb={1}
                    >
                        <Typography sx={{ marginRight: 2 }}>{`Agent ${index + 1}: `}</Typography>
                        <Typography flex={1}>{agentName}</Typography>
                        <TextField
                            size="small"
                            label="%"
                            variant="outlined"
                            sx={{ width: "60px" }}
                            value={percentages[index] || ""}
                            onChange={(e) => {
                                const newPercentages = [...percentages];
                                newPercentages[index] = e.target.value;
                                setPercentages(newPercentages);
                            }}
                        />
                        <TextField
                            size="small"
                            label="Custom"
                            variant="outlined"
                            sx={{ width: "70px" }}
                            value={customValues[index] || ""}
                            onChange={(e) => {
                                const newCustomValues = [...customValues];
                                newCustomValues[index] = e.target.value;
                                setCustomValues(newCustomValues);
                            }}
                        />
                        <TextField
                            size="small"
                            label="Remark"
                            variant="outlined"
                            sx={{ width: "120px" }}
                            value={customRemarks[index] || ""}
                            onChange={(e) => {
                                const newRemarks = [...customRemarks];
                                newRemarks[index] = e.target.value;
                                setCustomRemarks(newRemarks);
                            }}
                        />
                        <IconButton size="small" onClick={() => handleRemoveAgent(index)}>
                            <Icon>clear</Icon>
                        </IconButton>
                    </Box>
                ))}
            </Box>
            <Box width="100%" my={1}>
                <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems={"center"} mb={1}>
                <Typography sx={{ marginRight: 2 }}>Remarks: </Typography>
                <TextField
                    value={remark}
                    onChange={(e) => {
                        setRemark(e.target.value);
                    }}
                    multiline
                    rows={4}
                    fullWidth
                />
            </Box>
            <Box width="100%" my={3}>
                <Divider />
            </Box>
            <Button variant="contained" fullWidth color="success">
                Save
            </Button>
        </Box>
    );
}
