import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Container, Grid, IconButton, useTheme } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import React from "react";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { GridContainer, GridDivider, GridFlexBox, IconC, Label, Loading, Name } from "../../themes/themes";
import getContrastColor from "../../utils-functions/getContrastColor";
import convertDate from "../../functions/common-functions/convertDate";
import { checkSelected, getLabelColor } from "../../components/LeadsFlexBox";
import LabelsList from "../../components/LabelsList";
import { ActionsPanel } from "../../components/ActionsPanel";
import LeadActions from "../../components/LeadActions";
import { tokens } from "../../theme";
import { useInput } from "../../context-utils/InputContext";
import updateName from "../../functions/updateName";

export function LeadsFlexBoxComponent({
    displayLeads,
    setSearchAssign,
    setSearchLabel,
    showActions,
    search,
    setSearch,
    loading,
    followUpPage,
    select,
    handleClickDate,
    handleClickSelect,
    user,
    leadsMode,
    handleClickLabel,
    isMobile,
    enableSelect,
    handleClickOpen,
    limitActions,
    setLead,
    setOpenCard,
    displayOpenCard,
    search2,
    setSearch2,
    hideAssigned,
    handleUpdateLead,
}) {
    const handleClearSearch = (i) => {
        setSearch2((prev) => {
            if (prev) {
                const newSearch = [...prev];
                newSearch.splice(i, 1);
                return newSearch;
            } else {
                return [];
            }
        });
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const input = useInput();

    const handleClick = async (id, lead) => {
        const response = await input("Edit", "Edit the " + id, id, lead.name);
        console.log(response);
        if (response) {
            const newLead = await updateName(response, lead);
            handleUpdateLead(newLead);
        }
    };

    return (
        <Container maxWidth="md" disableGutters>
            <Grid container display={"flex"}>
                <Grid item xs={12} md flexWrap={"nowrap"}>
                    {/* put the search key here */}
                    {!arrayIsEmpty(search2) &&
                        search2.map((s, i) => (
                            <GridFlexBox xs={12} md={12} key={i}>
                                {s.id && (
                                    <>
                                        <Name ct>Search ID: </Name>
                                        <Name ct bold pl1>
                                            {s.id}
                                        </Name>
                                        <Name ct pl1>
                                            Search mode:{" "}
                                        </Name>
                                        <Name ct bold pl1>
                                            {s.mode}
                                        </Name>
                                        <Name ct pl1>
                                            Search value:{" "}
                                        </Name>
                                        <Name ct bold pl1>
                                            {s.value}
                                        </Name>
                                        <IconButton size="small" onClick={() => handleClearSearch(i)}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </>
                                )}
                            </GridFlexBox>
                        ))}

                    <Loading loading={loading} />
                    <GridDivider />
                    <Box m={1}>
                        {displayLeads &&
                            displayLeads.map((lead) => (
                                <React.Fragment key={followUpPage ? lead.followUpId : lead.id}>
                                    <LeadsFlexItem
                                        lead={lead}
                                        select={select}
                                        colors={colors}
                                        handleClick={handleClick}
                                        handleClickDate={handleClickDate}
                                        handleClickSelect={handleClickSelect}
                                        setSearchAssign={setSearchAssign}
                                        setSearchLabel={setSearchLabel}
                                        user={user}
                                        leadsMode={leadsMode}
                                        handleClickLabel={handleClickLabel}
                                        isMobile={isMobile}
                                        enableSelect={enableSelect}
                                        showActions={showActions}
                                        handleClickOpen={handleClickOpen}
                                        limitActions={limitActions}
                                        setLead={setLead}
                                        setOpenCard={setOpenCard}
                                        search={search}
                                        setSearch={setSearch}
                                        displayOpenCard={displayOpenCard}
                                        hideAssigned={hideAssigned}
                                    />
                                </React.Fragment>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}
function LeadsFlexItem({
    lead,
    select,
    colors,
    handleClick,
    handleClickDate,
    handleClickSelect,
    setSearchAssign,
    user,
    leadsMode,
    handleClickLabel,
    isMobile,
    enableSelect,
    setSearchLabel,
    showActions,
    handleClickOpen,
    limitActions,
    setLead,
    setOpenCard,
    displayOpenCard,
    hideAssigned,
}) {
    //
    const assignedLead = (lead) => {
        if (lead.type === "ASSIGNED_LEAD" && leadsMode === "SOURCE_LEADS") {
            return true;
        }
    };

    if (lead.type === "ASSIGNED_LEAD" && hideAssigned && leadsMode === "SOURCE_LEADS") return null;

    return (
        <React.Fragment>
            {!assignedLead(lead) && (
                <GridContainer
                    fs
                    bgColor={(select?.includes(lead.id) && colors.grey[800]) || lead.color || false}
                    sx={{
                        color: lead.color ? getContrastColor(lead.color) : false,
                    }}
                >
                    <GridFlexBox fs xs={6} md={6}>
                        <Name fs14 onClick={() => handleClick("name", lead)} ct cp bold>
                            {lead.name || "No Name"}
                        </Name>
                    </GridFlexBox>

                    <GridFlexBox fe xs md>
                        {displayOpenCard && (
                            <IconButton onClick={() => handleClickOpen(lead)}>
                                <OpenInNewIcon />
                            </IconButton>
                        )}

                        {/* <Name ct onClick={() => handleClickDate(lead)} cp> */}
                        <Name ct cp>
                            {moment(
                                convertDate(
                                    leadsMode === "SOURCE_LEADS"
                                        ? lead.created
                                        : lead.assignedDate || lead.created
                                )
                            ).format("DD-MM-YY LT")}
                        </Name>
                    </GridFlexBox>

                    {enableSelect && (
                        <GridFlexBox fe xs={1} md={1}>
                            <IconButton
                                size={"small"}
                                onClick={() => handleClickSelect(lead)}
                                style={{ padding: 0 }}
                            >
                                {checkSelected(lead, select)}
                            </IconButton>
                        </GridFlexBox>
                    )}

                    <GridFlexBox fs xs={12} md={12} gap={1}>
                        <Name fs14>{lead.phone || ""}</Name>
                        <Name fs14 pl1>
                            {lead.email || ""}
                        </Name>
                    </GridFlexBox>

                    {lead.remark && (
                        <GridFlexBox fs xs={12} md={12} gap={1}>
                            <Name fs14>{lead.remark || ""}</Name>
                        </GridFlexBox>
                    )}

                    {/* assignments */}
                    <GridFlexBox fe fw xs={12} md={12}>
                        {removeDuplicatedAssignments(lead.assignments)?.map((assignment) => (
                            <Assignment
                                key={assignment.assign?.email}
                                assignment={assignment}
                                setSearchAssign={setSearchAssign}
                                lead={lead}
                                user={user}
                            />
                        ))}
                    </GridFlexBox>

                    {/* labels */}
                    <GridFlexBox fs xs={8} md={8} mt8>
                        {!arrayIsEmpty(lead?.labels) && (
                            <LabelsList
                                labels={lead.labels}
                                leadsMode={leadsMode}
                                setSearchLabel={setSearchLabel}
                            />
                        )}
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            onClick={() => handleClickLabel(lead)}
                            style={{ cursor: "pointer" }}
                        >
                            <IconC color={colors.grey[500]}>add</IconC>
                            <Name fs12>Label</Name>
                        </Box>
                    </GridFlexBox>

                    <GridFlexBox fe xs={4} md={4} mt8>
                        <Name ct>{lead.source}</Name>
                    </GridFlexBox>

                    <GridFlexBox fe xs={12} md={12}>
                        <Name nowrap={isMobile}>{lead.campaignName}</Name>
                    </GridFlexBox>

                    <GridFlexBox fe xs={12} md={12}>
                        <Name nowrap={isMobile}>{lead.pageName}</Name>
                    </GridFlexBox>

                    {/* Action Panel */}
                    <GridFlexBox xs={12} md={12} fs gap={1} mt8>
                        <ActionsPanel lead={lead} />
                    </GridFlexBox>

                    {showActions && (
                        <Grid item xs={12} sx={{ marginTop: "8px" }}>
                            <LeadActions
                                lead={lead}
                                leadsMode={leadsMode}
                                limit={limitActions}
                                setLead={setLead}
                                setOpenCard={setOpenCard}
                            />
                        </Grid>
                    )}
                </GridContainer>
            )}

            {assignedLead(lead) && (
                <React.Fragment>
                    <GridContainer>
                        <GridFlexBox fs gap={1}>
                            {displayOpenCard && (
                                <IconButton onClick={() => handleClickOpen(lead)} sx={{ padding: 0 }}>
                                    <OpenInNewIcon />
                                </IconButton>
                            )}
                            <Label
                                onClick={() => setSearchAssign(lead.assignments[0]?.assign?.name)}
                                label={{
                                    fontColor: "white",
                                    color: green[500],
                                }}
                            >
                                {lead.assignments[0]?.assign?.name}
                            </Label>

                            {/* <Name onClick={() => handleClickDate(lead)} pl1> */}
                            <Name pl1>{moment(convertDate(lead.assignedDate)).format("DD-MM-YY LT")}</Name>
                        </GridFlexBox>
                        {/* labels */}
                        <GridFlexBox fs xs={12} md={12}>
                            {!arrayIsEmpty(lead?.labels) && (
                                <LabelsList
                                    labels={lead.labels}
                                    leadsMode={leadsMode}
                                    setSearchLabel={setSearchLabel}
                                />
                            )}
                        </GridFlexBox>
                        {showActions && (
                            <Grid item xs={12} sx={{ marginTop: "4px", marginBottom: "4px" }}>
                                <LeadActions
                                    lead={lead}
                                    leadsMode={leadsMode}
                                    limit={limitActions}
                                    setLead={setLead}
                                    setOpenCard={setOpenCard}
                                />
                            </Grid>
                        )}
                    </GridContainer>
                </React.Fragment>
            )}
            <GridDivider />
        </React.Fragment>
    );
}

export function Assignment({ assignment, setSearchAssign, lead, user }) {
    const display = () => {
        if (user.role === "Super Admin") return true;
        if (assignment.assign?.email === user.id) return true;
        if (assignment.assignBy === user.id) return true;
        return false;
    };
    return (
        display() && (
            <Box>
                <Label
                    onClick={() => setSearchAssign(assignment.assign?.name)}
                    label={{
                        fontColor: "white",
                        color: getLabelColor(lead, assignment.assign).color,
                    }}
                >
                    {assignment.assign?.name}
                </Label>
            </Box>
        )
    );
}

export const removeDuplicatedAssignments = (assignments) => {
    let array = [];
    assignments?.forEach((assignment) => {
        const found = array.find((a) => a.assign.email === assignment.assign.email);
        if (found) return;
        array.push(assignment);
    });
    return array;
};
export const getDataFromSelect = (select, leads) => {
    let array = [];
    select.forEach((id) => {
        const lead = leads.find((lead) => lead.id === id);
        array.push(lead);
    });
    return array;
};
