import DeleteIcon from "@mui/icons-material/Delete";
import Face6Icon from "@mui/icons-material/Face6";
import MemoryIcon from "@mui/icons-material/Memory";
import ShareIcon from "@mui/icons-material/Share";
import {
    Badge,
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    TextareaAutosize,
    Typography,
    useTheme,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { GridContainer, GridDivider, GridFlexBox, Loading, Name } from "../../themes/themes";
import ConnectWhatsappComponent from "./ConnectWhatsappComponent";

import getLeads from "../../functions/getLeads";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext } from "react";
import generateGptResponse from "../../components/ChatGPT/generateGptResponse";
import FullDialog from "../../components/FullDialog";
import SQLFileProcess from "../../components/SQLFileProcess";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { LeadsContext } from "../../context/LeadsContext";
import { StoreContext } from "../../context/StoreContext";
import { db } from "../../firebase/firebase-utils";
import loadFolders from "../../functions/loadFolders";
import useChooseFriend from "../../hooks/useChooseFriend";
import useScrollBottom from "../../hooks/useScrollBottom";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import convertDate from "../../utils-functions/convertDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import formatDate from "./../../functions/common-functions/formatDate";
import UploadExcelComponentV2 from "./UploadExcelComponentV2";
import UploadSQLFile from "./UploadSQLFile";
import deleteWhatsappBlast from "./deleteWhatsappBlast";
import loadWhatsappBlastHistory from "./loadWhatsappBlastHistory";
import saveWhatsappBlast from "./saveWhatsappBlast";
import sendMessageToNames from "./sendMessageToNames";
import shareWhatsappBlastHistory from "./shareWhatsappBlastHistory";

const REQUIRED_FIELDS = [
    { label: "Name", id: "name" },
    { label: "Phone", id: "phone" },
];

const chatGptPrompts = [
    { name: "Fix grammar", prompt: "Rewrite above using proper grammar" },
    { name: "Use emojis", prompt: "Rewrite above using proper grammar and use emojis" },
    { name: "Funny tone", prompt: "Rewrite above using funny tone" },
    { name: "Translate to Chinese", prompt: "Translate above to Chinese" },
    { name: "Translate to English", prompt: "Translate above to English" },
    { name: "Translate to Malay", prompt: "Translate above to Malay" },
];

export default function WhatsappBroadcastPage() {
    const [requiredFields, setRequiredFields] = useState(REQUIRED_FIELDS);
    const [names, setNames] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [delayTime, setDelayTime] = useState(11000);
    const [whatsappSession, setWhatsappSession] = useState("");
    const [whatsappBlastHistory, setWhatsappBlastHistory] = useState([]);
    const [historySize, setHistorySize] = useState(10);
    const [files, setFiles] = useState([]);
    const [selectedFolderDeatails, setSelectedFolderDetails] = useState({ folderName: "", folderId: "" });
    const [postPrompt, setPostPrompt] = useState(chatGptPrompts[0]);
    const [duplicatePhone, setDuplicatePhone] = useState(false);
    const [apiAddress, setApiAddress] = useState("");
    const [cursorPos, setCursorPos] = useState(null);
    const cursorPosRef = useRef(null);
    const [fullNamesList, setFullNamesList] = useState([]);
    const [secret, setSecret] = useState("");
    const [openCreateClient, setOpenCreateClient] = useState(false);
    const [tags, setTags] = useState("");
    const [isFormatInvalid, setIsFormatInvalid] = useState(false);
    const [saving, setSaving] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [openProcessCard, setOpenProcessCard] = useState(false);
    const [sqlTaskList, setSqlTaskList] = useState([]);
    const [numProcessing, setNumProcessing] = useState(0);
    const [retrigger, setRetrigger] = useState();
    const [loadTag, setLoadTag] = useState("");
    const [loadingTag, setLoadingTag] = useState(false);
    //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
    const [pollObject, setPollObject] = useState({ pollName: "", pollOptions: [] });
    const [open, setOpen] = React.useState(false);
    const [pollQuestion, setPollQuestion] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [pollItems, setPollItems] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [tagToChange, setTagToChange] = useState("");

    const confirmation = useConfirmation();

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchText("");
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChangePollQuestion = (e) => {
        setPollQuestion(e.target.value);
        setPollObject({ ...pollObject, pollName: e.target.value });
    };

    const handleChangePollItem = (e, index) => {
        const newItems = [...pollItems];
        newItems[index] = e.target.value;
        setPollItems(newItems);
        setPollObject({ ...pollObject, pollOptions: newItems });
    };

    const handleDeletePollItem = async (name) => {
        try {
            console.log("handleDeletePollItem:", name);
            setPollItems((currentPollItems) => {
                const newPollItems = currentPollItems.filter((item) => item !== name);
                setPollObject((currentPollObject) => ({
                    ...currentPollObject,
                    pollOptions: newPollItems,
                }));
                return newPollItems;
            });
        } catch (err) {
            console.log("handleDeletePoll err :", err);
        } finally {
            setIsLoading(false); // Reset loading state at the end of the function, regardless of outcome
        }
    };

    const handleAddPollItem = async () => {
        // if (isLoading) return; // Prevent the function from proceeding if it's already loading
        // setIsLoading(true);
        try {
            if (pollItems.length >= 12) {
                notification("Maximum limit for poll selection is 12.", "error");
                return;
            }
            const newItems = [...pollItems, "New Poll Selection"];
            console.log("newItems:", newItems);
            setPollItems(newItems);
            setPollObject({ ...pollObject, pollOptions: newItems });
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false); // Reset loading state at the end of the function, regardless of outcome
        }
    };

    const handleDeletePoll = () => {
        setPollQuestion("");
        setPollItems([]);
        setPollObject({});
    };

    const handleCreatePoll = () => {
        setOpen(true);
    };

    useEffect(() => {
        console.log("pollObject", pollObject);
    }, [pollObject]);

    const isTerminated = useRef(false); // Use to terminate sending for loop
    const inputRef = useRef(null);
    const notification = useNotification();

    const { folderLeads, setFolderLeads } = useContext(LeadsContext);
    const { folders, setFolders, setUnsubscribeStore } = useContext(StoreContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        console.log(names);
    }, [names]);

    // Load user's saved contact
    const loadUserSavedContact = async () => {
        if (!user) return;
        const tagArray = loadTag?.split(",").map((item) => item.trim());
        if (!tagArray) return;
        const collectionRef = collection(db, "userClients");
        const q = query(
            collectionRef,
            where("owner", "==", user.id),
            where("tags", "array-contains-any", tagArray),
            orderBy("date", "desc")
        );
        try {
            setLoadingTag(true);
            const snapShot = await getDocs(q);
            const savedContact = mapSnapshot(snapShot);
            setContactList(savedContact);
            setLoadingTag(false);
        } catch (err) {
            console.log(err);
            setLoadingTag(false);
        }
        console.log("tagArray:", tagArray);
    };

    // Listener for the user's sqlTasks
    useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "users", user.id, "sqlTasks");
        const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
            const data = mapSnapshot(snapshot);
            console.log("SQL tasks list: ", data);
            setSqlTaskList(data);
        });
        return unsubscribe;
    }, [user]);

    useEffect(() => {
        let count = 0;

        sqlTaskList.forEach((task) => {
            if (task.status === "running") {
                count++; // Increment count if the status is "running"
            }
        });

        setNumProcessing(count);
    }, [sqlTaskList]);

    useEffect(() => {
        let unsubscribe = () => {};
        if (!folders) {
            unsubscribe = loadFolders(user, setFolders);

            console.log(folders, "cacac");

            setUnsubscribeStore((array) => {
                if (array) {
                    let newArray = [...array];
                    newArray.push(unsubscribe);
                    setUnsubscribeStore(newArray);
                } else {
                    setUnsubscribeStore([unsubscribe]);
                }
            });
        }
    }, []);

    useEffect(() => {
        const unsubscribe = loadWhatsappBlastHistory(user, setWhatsappBlastHistory, historySize);
        return unsubscribe;
    }, [historySize, user]);

    useEffect(() => {
        const names = [];
        if (folderLeads !== undefined) {
            const names = folderLeads.map((lead) => ({
                name: lead.name,
                phone: formatPhoneNumber(lead.phone),
                status: "",
            }));
            setNames(names);
        } else {
            setNames(names);
        }
    }, [folderLeads]);

    useEffect(() => {
        if (inputRef.current && cursorPosRef.current !== null) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(cursorPosRef.current, cursorPosRef.current);
        }
    }, [message]);

    useEffect(() => {
        if (cursorPosRef.current !== null) {
            setCursorPos(cursorPosRef.current);
            cursorPosRef.current = null;
        }
    }, [message]);

    useEffect(() => {
        const duplicatedPhones = checkDuplicatePhone(names);
        if (duplicatedPhones) {
            setDuplicatePhone(true);
        } else {
            setDuplicatePhone(false);
        }
    }, [names]);

    useEffect(() => {
        loadUserSavedContact();
    }, [loadTag, refresh]);

    const theme = useTheme();
    const colorMode = theme.palette.mode;

    const handleClickOpenCreateClient = () => {
        setOpenCreateClient(true);
    };

    const handleCloseCreateClient = () => {
        setOpenCreateClient(false);
    };

    const handleFolderChange = (e) => {
        let folderObject = {};

        folders.forEach(async (folder) => {
            if (folder.folderName === e.target.value) {
                console.log("in if");
                folderObject = folder;

                console.log(folderObject);
                await getLeads(user, setFolderLeads, "", "", () => {}, "", folder, "", "");

                setSelectedFolderDetails({ folderName: folder.folderName, folderId: folder.id });
            }
        });
    };

    // const handleUpload = (data) => {
    //     console.log("handleUpload data:",data);
    //     const names = data.map((item) => {
    //         const name = item.name || "";
    //         let number = item.phone || "";
    //         if (typeof item.phone === "number") {
    //             number = item.phone.toString();
    //         }
    //         return { name: name, phone: number, status: "" };
    //     });

    //     setNames(names);
    // };

    const handleUpload = (data) => {
        console.log("handleUpload data:", data);

        const names = data.map((item) => {
            let object = {};
            for (let key in item) {
                if (Object.prototype.hasOwnProperty.call(item, key)) {
                    let value = item[key] || "";
                    if (typeof value === "number") {
                        value = value.toString();
                    }
                    object[key] = value;
                }
            }
            // Ensure 'status' is included even if not present in the item
            if (!Object.prototype.hasOwnProperty.call(object, "status")) {
                object["status"] = "";
            }
            return object;
        });
        setNames(names);
    };

    const handleClickSend = async () => {
        isTerminated.current = false;

        if (!message && !files.length && pollObject?.pollOptions?.length === 0) {
            toast.error("Please enter a message,create a poll or attach a file to send");
        } else {
            setLoading(true);
            let newNames = names.map((item) => ({ ...item, status: "" }));

            setNames(newNames);

            const status = await sendMessageToNames(
                newNames,
                message,
                whatsappSession,
                setNames,
                delayTime,
                isTerminated,
                files,
                apiAddress,
                secret,
                pollObject, //added by wayne : 23-2-24 to add in poll object on whatsappbroadcast
                user
            );

            setMessage("");
            newNames = newNames.map((item, i) => ({ ...item, status: status[i] }));
            console.log(newNames);
            setFullNamesList(newNames);
            saveWhatsappBlast(newNames, message, files, user);
            setLoading(false);
        }
    };

    const handleDeleteName = (i) => {
        const newNames = [...names];
        newNames.splice(i, 1);
        setNames(newNames);
    };

    const handleClickEmoji = (e) => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const emoji = `${e.emoji}`;

        setMessage((prevMessage) => prevMessage.slice(0, startPos) + emoji + prevMessage.slice(endPos));
        const newCursorPosition = startPos + emoji.length;
        cursorPosRef.current = newCursorPosition; // Save the new cursor position to the ref
    };

    // const handleClickName = () => {
    //     const input = inputRef.current;
    //     const startPos = input.selectionStart;
    //     const endPos = input.selectionEnd;

    //     setMessage(message.slice(0, startPos) + `{name}` + message.slice(endPos));
    //     input.focus();
    // };

    const handleClickName = (name) => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;

        setMessage(message.slice(0, startPos) + name + message.slice(endPos));
        input.focus();
    };
    const handleFile = async (files) => {
        console.log(files);
        setFiles(files);
    };

    const handlePasteData = (data) => {
        console.log(data);
        const names = data.reduce((acc, item) => {
            if (item?.[0]?.trim() && item?.[1]?.trim()) {
                acc.push({ name: item[0].trim(), phone: item[1].trim(), status: "" });
            } else {
                toast.error("Please make sure name and phone are filled");
            }
            return acc;
        }, []);
        setNames(names);
    };

    const handleClickHistory = (item) => {
        setMessage(item.message);
        setFiles(item.files);
    };

    const handleClickDeleteHistory = async (item) => {
        const response = await confirmation("Delete history?", "Click ok to confirm");
        if (response) {
            await deleteWhatsappBlast(item.id, user);
        }
    };

    const handleChangePrompt = (event) => {
        const promptObj = chatGptPrompts.find((item) => item.name === event.target.value);
        console.log(promptObj);
        setPostPrompt(promptObj);
    };

    const handleAiGenerate = async () => {
        await saveWhatsappBlast(names, message, files, user);
        const prompt = `${message}\n\n${postPrompt.prompt}`;
        console.log(prompt);
        generateGptResponse(prompt, setMessage, setGenerating, "gpt-3.5-turbo");
    };

    const chooseFriend = useChooseFriend();

    const handleSaveHistory = async (item) => {
        const friend = await chooseFriend();
        await shareWhatsappBlastHistory(item, friend, user);
    };

    const handleRemoveDuplicatePhones = () => {
        const newNames = removeDuplicatePhone(names);
        setNames(newNames);
    };

    const handleClickFilterNames = (status) => {
        if (arrayIsEmpty(fullNamesList)) {
            setFullNamesList(names);
            setRetrigger(status);
            return;
        }

        if (status === "all") {
            if (arrayIsEmpty(fullNamesList)) return;
            setNames(fullNamesList);
            return;
        }

        let newNames;

        if (status === "file") {
            newNames = fullNamesList.filter((item) => item.file);
        } else {
            newNames = fullNamesList.filter((item) => item.status === status);
        }

        if (newNames) {
            setNames(newNames);
        }
    };

    useEffect(() => {
        handleClickFilterNames(retrigger);
    }, [retrigger]);

    // useEffect(() => {
    //     setFilteredNames(names); // Initially set filteredNames to the full list
    // }, [names]);

    const handleSaveClientContacts = async () => {
        console.log("tags: ", tags);
        const finalTags = tags.split(", ").map((tag) => tag.trim());

        if (finalTags.length > 0 && finalTags.every((tag) => tag !== "")) {
            setIsFormatInvalid(false);
            handleCloseCreateClient();

            const userClientsRef = collection(db, "userClients");
            const userRef = doc(db, "users", user.id);

            setSaving(true);

            const addClientPromises = names.map(async (client) => {
                const { name, phone } = client;
                const clientData = {
                    name,
                    phone,
                    owner: user.id,
                    date: new Date(),
                    tags: finalTags,
                };

                try {
                    // Use phone as the document ID
                    const clientDocRef = doc(userClientsRef, phone);
                    await setDoc(clientDocRef, clientData, { merge: true });
                    console.log(`Added/Updated client "${name}" successfully.`);

                    await updateDoc(userRef, {
                        userClientTags: arrayUnion(...finalTags),
                    });
                    console.log(`Added/Updated userClientTags "${finalTags}" to ${user.id}.`);
                } catch (error) {
                    console.error(`Error adding/updating client "${name}":`, error);
                }
            });

            try {
                await Promise.all(addClientPromises);
                console.log("All clients added/updated successfully.");
                notification("All clients added/updated successfully.", "success");
                setTags("");
                setSaving(false);
            } catch (error) {
                console.error("Error adding/updating clients:", error);
            }
        } else {
            setTags("");
            setIsFormatInvalid(true);
        }
    };

    const handleChange = (e) => {
        setMessage(e.target.value);
    };
    const handleOpenTagDialog = () => {
        setOpenTagDialog(true);
    };

    const handleCloseTagDialog = () => {
        setOpenTagDialog(false);
    };

    const handleClickTag = (tag) => {
        console.log(tag);
        setLoadTag(tag);
        setTagToChange(tag);
    };

    const handleDeleteTag = async (tag) => {
        const response = await confirmation(
            "Confirmed to remove tag ?",
            `${tag} will be removed from all user clients.`
        );
        if (response) {
            try {
                const collectionRef = collection(db, "userClients"); // Correct collection name now used
                const q = query(
                    collectionRef,
                    where("tags", "array-contains", tag),
                    where("owner", "==", user.id)
                );
                const snapshot = await getDocs(q);

                if (!snapshot.empty) {
                    const promises = snapshot.docs.map((doc) => {
                        updateDoc(doc.ref, {
                            tags: arrayRemove(tag),
                        });
                    });
                    await Promise.all(promises); // Perform all updates concurrently
                } else {
                    console.log("No documents found with the specified tag to remove.");
                }

                const userDocRef = doc(db, "users", user.id); // Adjust path as necessary
                await updateDoc(userDocRef, {
                    userClientTags: arrayRemove(tag),
                });
                setLoadTag("");
                notification("Tag deleted.", "success");
            } catch (error) {
                console.error("Error removing tag:", error);
            }
        }
    };

    const columns = [
        { field: "no", headerName: "No", width: 90 },
        { field: "name", headerName: "Name", width: 150 },
        { field: "phone", headerName: "Phone", width: 250 },
        // { field: "tags", headerName: "Tags", width: 150 },
        { field: "date", headerName: "Saved Date", width: 150 },
    ];

    const formatDate = (timestamp) => {
        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return date.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric", // Adjust the date format as needed
        });
    };

    const rows =
        contactList?.map((contact, index) => ({
            ...contact,
            no: index + 1,
            date: formatDate(contact.date), // Assuming 'date' is the field with the Timestamp
        })) || [];

    const deleteContacts = async () => {
        const response = await confirmation(
            "Delete contact",
            "Are you sure you want to delete these contact(s)?"
        );
        if (!response) return;

        const collectionRef = collection(db, "userClients");
        rowSelectionModel.forEach(async (id) => {
            await deleteDoc(doc(collectionRef, id));
        });
        setRefresh(!refresh);
        notification("Contact(s) successfully.", "success");
    };

    const handleAddNameList = () => {
        const unique = new Map(); // Create a new Map to hold unique items
        const selectedSet = new Set(rowSelectionModel); // Use a Set for efficient lookup

        // Combine both arrays and iterate over them
        [...names, ...contactList].forEach((item) => {
            if (selectedSet.has(item.phone)) {
                // Check if the item's phone is in the selection model
                unique.set(item.phone, item); // Set the phone number as the key, item as the value
            }
        });

        // Convert the Map values back to an array and update state
        const array = Array.from(unique.values());

        setNames(array);
        if (array.length === 0) {
            // Notification to indicate that contacts have been successfully added
            notification(`No contact selected.`, "error");
        } else {
            notification(`Contact with tag ${loadTag} added.`, "success");
        }
    };

    const handleSaveTagName = async () => {
        const response = await confirmation(
            "Update tag name",
            "Are you sure you want to update the tag name?"
        );
        if (!response) return;

        try {
            const collectionRef = collection(db, "userClients");
            const q = query(
                collectionRef,
                where("tags", "array-contains", loadTag),
                where("owner", "==", user.id)
            );
            const snapshot = await getDocs(q);

            if (!snapshot.empty) {
                const promises = snapshot.docs.map((doc) => {
                    return updateDoc(doc.ref, {
                        tags: arrayUnion(tagToChange), // Add the new tag
                    }).then(() =>
                        updateDoc(doc.ref, {
                            tags: arrayRemove(loadTag), // Remove the old tag
                        })
                    );
                });
                await Promise.all(promises);
            } else {
                console.log("No documents found with the specified tag to update.");
            }

            const userDocRef = doc(db, "users", user.id);
            await updateDoc(userDocRef, {
                userClientTags: arrayUnion(tagToChange),
            }).then(() =>
                updateDoc(userDocRef, {
                    userClientTags: arrayRemove(loadTag),
                })
            );

            setLoadTag(tagToChange);
            notification("Tag updated successfully.", "success");
        } catch (error) {
            console.error("Error updating tag:", error);
            notification("Error while updating tag.", "error");
        }
    };

    function CustomToolbar({ onDelete, disabled }) {
        return (
            <GridToolbarContainer>
                <GridToolbar />
                <GridToolbarQuickFilter />
                <Box display="flex" alignItems={"center"} pt="4px">
                    <Button
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={onDelete}
                        disabled={contactList.length === 0 || disabled}
                        size="small"
                    >
                        Delete
                    </Button>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Container maxWidth="none">
                <FullDialog
                    open={openProcessCard}
                    handleClose={() => setOpenProcessCard(false)}
                    title={"SQL PDF Process"}
                    Component={
                        <SQLFileProcess
                            sqlTaskList={sqlTaskList}
                            names={names}
                            setNames={setNames}
                            setOpenProcessCard={setOpenProcessCard}
                            handleClickFilterNames={handleClickFilterNames}
                        />
                    }
                />
                <Box position={"fixed"} bottom={"10px"} left={"10px"} zIndex={1000}>
                    <Fab
                        color="primary"
                        aria-label="sql_pdf_process"
                        onClick={() => setOpenProcessCard(true)}
                        disabled={names.length < 1}
                    >
                        <Badge badgeContent={numProcessing} color="secondary">
                            <MemoryIcon />
                        </Badge>
                    </Fab>
                </Box>

                <GridContainer alignItems="flex-start">
                    <GridFlexBox xs={12} md={3}>
                        <GridContainer>
                            <GridFlexBox>
                                <ConnectWhatsappComponent
                                    whatsappUser={user}
                                    setApiAddress={setApiAddress}
                                    setWhatsappSession={setWhatsappSession}
                                    setSecret={setSecret}
                                />
                            </GridFlexBox>
                            <GridDivider />
                            <GridFlexBox xs={12}>
                                <UploadExcelComponentV2 handleUpload={handleUpload} />
                                <UploadSQLFile user={user} />
                            </GridFlexBox>
                            <GridFlexBox>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Folder</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedFolderDeatails.folderName}
                                            label="Select Folder"
                                            onChange={handleFolderChange}
                                        >
                                            {folders === undefined || folders.length == 0 ? (
                                                <MenuItem key={Math.random()} value="Loading...">
                                                    Loading...
                                                </MenuItem>
                                            ) : (
                                                folders.map((folder) => (
                                                    <MenuItem key={folder.id} value={folder.folderName}>
                                                        {folder.folderName}
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </GridFlexBox>
                            <GridFlexBox>
                                <ExcelLikeInput handlePasteData={handlePasteData} />
                            </GridFlexBox>
                            {/* This is the saved contact list panel */}
                            <GridDivider />
                            <GridContainer sx={{ marginLeft: { xs: "0", md: "8px" } }}>
                                <GridFlexBox>
                                    <Button
                                        onClick={handleOpenTagDialog}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        CONTACTS MANAGEMENT
                                    </Button>
                                </GridFlexBox>
                            </GridContainer>
                            <GridDivider />
                            {/* <GridContainer sx={{ marginLeft: { xs: "0", md: "8px" } }}>
                                <GridFlexBox>
                                    <SavedContactList
                                        names={names}
                                        setNames={setNames}
                                        contactList={contactList ? contactList : []}
                                        setContactList={setContactList}
                                        loadTag={loadTag}
                                        setLoadTag={setLoadTag}
                                        loadingTag={loadingTag}
                                        setLoadingTag={setLoadingTag}
                                        loadUserSavedContact={loadUserSavedContact}
                                        user={user}
                                    />
                                </GridFlexBox>
                            </GridContainer> */}
                        </GridContainer>
                    </GridFlexBox>
                    <GridFlexBox xs={12} md={4} fs>
                        <GridContainer>
                            <GridFlexBox>
                                <Paper
                                    variant="outlined"
                                    sx={{
                                        margin: "8px",
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                        width: "100%",
                                        height: "400px",
                                    }}
                                >
                                    <Box m={"8px"}>
                                        <GridContainer sx={{ marginLeft: { xs: "0", md: "8px" } }}>
                                            <GridFlexBox sb>
                                                <Dialog
                                                    open={openCreateClient}
                                                    onClose={handleCloseCreateClient}
                                                >
                                                    <DialogTitle>Save Contact</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            {`Please write a tag(s) for your contacts. For multiple tags, please separate them using ','. For example: tag1, tag2`}
                                                        </DialogContentText>
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            label="Tags"
                                                            type="text"
                                                            fullWidth
                                                            variant="standard"
                                                            value={tags}
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                                setTags(e.target.value);
                                                            }}
                                                            error={isFormatInvalid}
                                                            helperText={
                                                                isFormatInvalid &&
                                                                "Please follow the correct format."
                                                            }
                                                        />
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleCloseCreateClient}>
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={handleSaveClientContacts}>
                                                            Save
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                                <Name fs14>Send to names:</Name>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    disabled={!!duplicatePhone || names.length < 1}
                                                    sx={{ marginRight: "8px" }}
                                                    onClick={handleClickOpenCreateClient}
                                                >
                                                    Save Contact
                                                </Button>
                                            </GridFlexBox>
                                            <GridFlexBox fs>
                                                <Name color={red[500]}>
                                                    {duplicatePhone ? "Duplicated numbers" : ""}
                                                </Name>
                                                {duplicatePhone && (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color="error"
                                                        sx={{ margin: "0 8px" }}
                                                        onClick={handleRemoveDuplicatePhones}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </GridFlexBox>
                                            <GridDivider />
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box display="flex" width="100%" alignItems="center">
                                                        <Typography component="div" sx={{ mr: 2 }}>
                                                            Search:
                                                        </Typography>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            sx={{ marginRight: "8px" }}
                                                            value={searchText}
                                                            onChange={handleSearchChange}
                                                        />
                                                        <Typography
                                                            onClick={handleClearSearch}
                                                            sx={{ marginRight: "8px", cursor: "pointer" }}
                                                        >
                                                            Clear
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <GridDivider />
                                            <Loading loading={saving} />
                                            <GridFlexBox xs={4.5} lg={4.5} fs>
                                                <Name ct whiteSpace="nowrap">
                                                    Name
                                                </Name>
                                            </GridFlexBox>
                                            <GridFlexBox xs={4.5} lg={4.5} fs>
                                                <Name ct>Phone</Name>
                                            </GridFlexBox>
                                            <GridFlexBox xs={3} lg={3} fs>
                                                <Name ct>Status</Name>
                                            </GridFlexBox>
                                            <GridDivider />
                                            {/* {names.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <GridFlexBox xs={4.5} lg={4.5} fs>
                                                        <Name ct whiteSpace="nowrap">
                                                            {item.name}
                                                        </Name>
                                                    </GridFlexBox>
                                                    <GridFlexBox xs={4.5} lg={4.5} fs>
                                                        <Name ct>{item.phone}</Name>
                                                    </GridFlexBox>
                                                    <GridFlexBox xs={1} lg={1} fs>
                                                        {item.file && (
                                                            <Tooltip title="Click to view the file">
                                                                <IconButton
                                                                    color={item.file ? "success" : "error"}
                                                                    size="small"
                                                                    disabled={!item.file}
                                                                    onClick={() => {
                                                                        window.open(item.file, "_blank");
                                                                    }}
                                                                >
                                                                    <InsertDriveFileIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </GridFlexBox>
                                                    <GridFlexBox xs={1} lg={1} fs>
                                                        <Name
                                                            color={
                                                                item.status === "sent" ? green[500] : red[500]
                                                            }
                                                            ct
                                                        >
                                                            {item.status}
                                                        </Name>
                                                    </GridFlexBox>
                                                    <GridFlexBox xs={1} lg={1} fs>
                                                        <IconButton onClick={() => handleDeleteName(i)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </GridFlexBox>
                                                </React.Fragment>
                                            ))} */}
                                            {names
                                                .filter((item) => {
                                                    if (!searchText) return true; // If no search text, show all names
                                                    console.log("searchText:", searchText);
                                                    const lowerCaseSearchText =
                                                        searchText?.toLowerCase() || "";
                                                    return (
                                                        item.name
                                                            .toLowerCase()
                                                            .includes(lowerCaseSearchText) ||
                                                        item.phone.includes(searchText)
                                                    );
                                                })
                                                .map((item, i) => (
                                                    <React.Fragment key={i}>
                                                        <GridFlexBox xs={4.5} lg={4.5} fs>
                                                            <Name ct whiteSpace="nowrap">
                                                                {item.name}
                                                            </Name>
                                                        </GridFlexBox>
                                                        <GridFlexBox xs={4.5} lg={4.5} fs>
                                                            <Name ct>{item.phone}</Name>
                                                        </GridFlexBox>
                                                        <GridFlexBox xs={1} lg={1} fs>
                                                            <Name
                                                                color={
                                                                    item.status === "sent"
                                                                        ? green[500]
                                                                        : red[500]
                                                                }
                                                                ct
                                                            >
                                                                {item.status}
                                                            </Name>
                                                        </GridFlexBox>
                                                        <GridFlexBox xs={1} lg={1} fs>
                                                            <IconButton onClick={() => handleDeleteName(i)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </GridFlexBox>
                                                    </React.Fragment>
                                                ))}
                                        </GridContainer>
                                    </Box>
                                </Paper>
                            </GridFlexBox>
                            <GridFlexBox fe>
                                <Name>Filter by:</Name>
                                <Button onClick={() => handleClickFilterNames("all")}>all</Button>
                                <Button
                                    onClick={() => handleClickFilterNames("sent")}
                                    sx={{ color: green[500] }}
                                >
                                    sent
                                </Button>
                                <Button onClick={() => handleClickFilterNames("failed")} color="error">
                                    failed
                                </Button>
                                <Button
                                    onClick={() => {
                                        handleClickFilterNames("file");
                                    }}
                                    color="success"
                                >
                                    file
                                </Button>
                            </GridFlexBox>
                            <GridFlexBox>
                                {WhatsappHistoryComponent(
                                    whatsappBlastHistory,
                                    handleClickHistory,
                                    handleSaveHistory,
                                    setNames,
                                    setFullNamesList,
                                    handleClickDeleteHistory,
                                    setHistorySize
                                )}
                            </GridFlexBox>
                        </GridContainer>
                    </GridFlexBox>
                    <GridFlexBox xs={12} md={5} fs>
                        <GridContainer>
                            <GridFlexBox fs>
                                <Name fs14>Delay by</Name>
                                <Select
                                    value={delayTime}
                                    onChange={(e) => setDelayTime(e.target.value)}
                                    size="small"
                                    sx={{ marginLeft: "8px" }}
                                >
                                    <MenuItem value={1000}>1 second</MenuItem>
                                    <MenuItem value={3000}>3 seconds</MenuItem>
                                    <MenuItem value={5000}>5 seconds</MenuItem>
                                    <MenuItem value={7000}>7 seconds</MenuItem>
                                    <MenuItem value={9000}>9 seconds</MenuItem>
                                    <MenuItem value={11000}>11 seconds</MenuItem>
                                    <MenuItem value={20000}>20 seconds</MenuItem>
                                    <MenuItem value={30000}>30 seconds</MenuItem>
                                    <MenuItem value={60000}>60 seconds</MenuItem>
                                    <MenuItem value={120000}>2 mins</MenuItem>
                                    <MenuItem value={180000}>3 mins</MenuItem>
                                </Select>
                            </GridFlexBox>
                            <GridFlexBox>
                                <Box display="flex" flexWrap="wrap" gap={1} marginY={1}>
                                    {[...new Set(names.flatMap(Object.keys))]
                                        .filter((key) => key !== "status")
                                        .map((key, index) => (
                                            <Chip
                                                key={index}
                                                label={`{${key}}`}
                                                onClick={() => handleClickName(`{${key}}`)}
                                                style={{ maxWidth: "100px" }}
                                            />
                                        ))}
                                </Box>
                            </GridFlexBox>
                            <Loading loading={loading} />

                            <GridFlexBox>
                                <TextField
                                    multiline
                                    autoFocus
                                    rows={10}
                                    value={message}
                                    onChange={handleChange}
                                    fullWidth
                                    placeholder="Your message here"
                                    inputRef={inputRef}
                                />
                            </GridFlexBox>
                            <Loading loading={generating} />
                            {!arrayIsEmpty(files) && (
                                <GridFlexBox fs>
                                    <Box sx={{ marginBottom: "8px", display: "flex", gap: "8px" }}>
                                        <>
                                            <Typography
                                                sx={{ color: "green", whiteSpace: "break-spaces" }}
                                                variant="h6"
                                            >
                                                {files[0].name}
                                            </Typography>
                                            <Typography
                                                sx={{ cursor: "pointer", color: "red" }}
                                                onClick={() => setFiles([])}
                                                variant="h6"
                                            >
                                                X
                                            </Typography>
                                        </>
                                    </Box>
                                </GridFlexBox>
                            )}
                            <GridFlexBox fs mt2 fw>
                                <Button
                                    variant="contained"
                                    onClick={handleClickSend}
                                    disabled={loading || !whatsappSession}
                                    sx={{ marginBottom: "8px" }}
                                >
                                    Send
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        isTerminated.current = true;
                                    }}
                                    sx={{ marginBottom: "8px", marginLeft: "16px" }}
                                    disabled={!loading}
                                >
                                    Stop
                                </Button>

                                <Box sx={{ marginBottom: "8px", marginLeft: "16px" }}>
                                    <UploadFilesButton handleFile={handleFile} />
                                </Box>

                                <Box sx={{ marginBottom: "8px", marginLeft: "16px", marginRight: "8px" }}>
                                    <Button variant="contained" color="warning" onClick={handleAiGenerate}>
                                        AI Generate
                                    </Button>
                                </Box>
                                <Box sx={{ marginBottom: "8px", width: "250px" }} gap={"8px"}>
                                    <Select
                                        labelId="chatGpt-select-label"
                                        id="chatGpt-select"
                                        value={postPrompt.name}
                                        onChange={handleChangePrompt}
                                        sx={{ width: "100%" }}
                                        size="small"
                                    >
                                        {chatGptPrompts.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </GridFlexBox>
                            <GridFlexBox>
                                <EmojiPicker
                                    width={"100%"}
                                    height={"400px"}
                                    onEmojiClick={handleClickEmoji}
                                    theme={colorMode}
                                    autoFocusSearch={false}
                                    searchDisabled={true}
                                />
                            </GridFlexBox>
                        </GridContainer>
                    </GridFlexBox>
                </GridContainer>
            </Container>
            <Dialog
                open={openTagDialog}
                onClose={handleCloseTagDialog}
                fullWidth={true} // Ensure it uses the full width available
                maxWidth="xl"
            >
                <DialogTitle>Contacts Management</DialogTitle>
                <DialogContent sx={{ marginBottom: 2 }}>
                    <Divider sx={{ marginBottom: 2 }}>Available tags</Divider>
                    {user?.userClientTags
                        ? [
                              user.userClientTags[user.userClientTags.length - 1],
                              ...user.userClientTags.slice(0, -1),
                          ].map((tag, index) => (
                              <Chip
                                  key={index}
                                  label={tag}
                                  color={loadTag === tag ? "success" : "primary"}
                                  sx={{ marginRight: 1, marginBottom: 1 }}
                                  onClick={() => handleClickTag(tag)}
                                  onDelete={() => handleDeleteTag(tag)}
                              />
                          ))
                        : "There is no tag avaialble."}
                    <Divider sx={{ marginBottom: 2 }} />
                    {loadTag && (
                        <Box sx={{ marginBottom: 2 }}>
                            <Stack
                                direction={{ xs: "column", sm: "row" }} // vertical on extra-small devices, horizontal on small and above
                                spacing={2} // Adjust spacing between items
                                alignItems="center" // Align items vertically at the center
                            >
                                <Typography>Edit tag name :</Typography>
                                <TextField
                                    size="small"
                                    value={tagToChange}
                                    onChange={(e) => setTagToChange(e.target.value)}
                                    sx={{
                                        width: { xs: "100%", sm: "50%" }, // 100% width on xs screens, 50% on sm and larger screens
                                    }}
                                />
                                <Button onClick={handleSaveTagName}>Save Changes</Button>
                            </Stack>
                        </Box>
                    )}
                    <Divider sx={{ marginBottom: 2 }} />
                    <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                            slots={{
                                toolbar: () => <CustomToolbar onDelete={deleteContacts} />,
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[30]}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                console.log(newRowSelectionModel);
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseTagDialog}>Cancel</Button>
                    <Button onClick={handleAddNameList}>Add to Send List</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const ExcelLikeInput = ({ handlePasteData }) => {
    const [textAreaData, setTextAreaData] = useState("");

    const handleDataProcessing = () => {
        let pasteData = textAreaData.split("\n").map((row) => {
            return row.includes(",") ? row.split(",") : row.split("\t");
        });
        console.log(pasteData);
        handlePasteData(pasteData);
    };

    return (
        <Grid container display={"flex"} flexDirection={"column"}>
            <GridFlexBox>
                <TextareaAutosize
                    minRows={5}
                    placeholder="Paste Names and Phones. Copy from Excel or separate name and phone with ','"
                    value={textAreaData}
                    onChange={(e) => setTextAreaData(e.target.value)}
                />
            </GridFlexBox>
            <GridFlexBox m1>
                <Button variant="contained" color="primary" onClick={handleDataProcessing}>
                    Process Data
                </Button>
            </GridFlexBox>
        </Grid>
    );
};

function WhatsappHistoryComponent(
    whatsappBlastHistory,
    handleClickHistory,
    handleSaveHistory,
    setNames,
    setFullNamesList,
    handleClickDeleteHistory,
    setHistorySize
) {
    const ref = useRef(null);
    const isBottom = useScrollBottom(ref);

    useEffect(() => {
        if (isBottom) {
            console.log("bottom");
            setHistorySize((prev) => prev + 10);
        }
    }, [isBottom]);

    return (
        <Paper
            ref={ref}
            variant="outlined"
            sx={{
                margin: "8px",
                overflowY: "scroll",
                overflowX: "hidden",
                width: "100%",
                height: "300px",
            }}
        >
            <Box sx={{ margin: "8px" }}>
                <GridContainer sx={{ marginLeft: { xs: "0", md: "8px" } }}>
                    <GridFlexBox fs>
                        <Name fs14>Whatsapp Blast History:</Name>
                    </GridFlexBox>
                    <GridDivider />
                    <ListComponent
                        list={whatsappBlastHistory}
                        handleClickList={handleClickHistory}
                        handleShare={handleSaveHistory}
                        setNames={setNames}
                        handleDelete={handleClickDeleteHistory}
                        setFullNamesList={setFullNamesList}
                    />
                </GridContainer>
            </Box>
        </Paper>
    );
}

function ListComponent({ list, handleClickList, handleShare, setNames, setFullNamesList, handleDelete }) {
    return (
        <React.Fragment>
            {list.map((item, i) => (
                <React.Fragment key={i}>
                    <GridFlexBox cp xs={4} fs onClick={() => handleClickList(item)}>
                        <Name ct nowrap>
                            {formatDate(convertDate(item.date), true)}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox cp xs={5} fs onClick={() => handleClickList(item)}>
                        <Name ct nowrap>
                            {item.message}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox xs={1} fs>
                        <IconButton onClick={() => handleShare(item)}>
                            <ShareIcon />
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox xs={1} fs>
                        <IconButton
                            onClick={() => {
                                setFullNamesList(item.names);
                                setNames(item.names);
                            }}
                        >
                            <Face6Icon />
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox xs={1} fs>
                        <IconButton onClick={() => handleDelete(item)}>
                            <DeleteIcon />
                        </IconButton>
                    </GridFlexBox>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}

function checkDuplicatePhone(names) {
    const phones = names.map((nameObj) => {
        // Remove spaces, special characters, and alphabets
        const cleanedPhone = formatPhoneNumber(nameObj.phone);
        return cleanedPhone;
    });

    return new Set(phones).size !== phones.length;
}

function removeDuplicatePhone(names) {
    const unique = names.reduce((acc, current) => {
        // Remove spaces, special characters, and alphabets
        const cleanedPhone = formatPhoneNumber(current.phone);

        if (!acc.some((nameObj) => nameObj.phone === cleanedPhone)) {
            // Update the phone number to be the cleaned one
            acc.push({ ...current, phone: cleanedPhone });
        }
        return acc;
    }, []);

    return unique;
}

function formatPhoneNumber(phone) {
    let p = phone;
    if (!p) return "";
    if (typeof p === "number") p = p.toString();
    let cleanedPhone = p.replace(/[^0-9]/g, "");

    if (cleanedPhone[0] === "0") {
        cleanedPhone = "6" + cleanedPhone;
    }
    return cleanedPhone;
}
