import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FacebookIcon from "@mui/icons-material/Facebook";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InstagramIcon from "@mui/icons-material/Instagram";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SendIcon from "@mui/icons-material/Send";

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    Menu,
    MenuItem,
    Paper,
    Popover,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EmojiPicker from "emoji-picker-react";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { red } from "@mui/material/colors";
import { toast } from "react-hot-toast";
import ImageIndicator from "../../components/ChatInbox/ImageIndicator";
import AddItemsMenu from "../../components/Menus/AddItemsMenu";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import AccountsDialog from "./AccountsDialog";
import ChatMessageComponent from "./ChatMessageComponent";
import { ChatRoomComponent } from "./ChatRoomComponent";
import SortDialog from "./FilterDialog";
import FlowComponent from "./FlowComponent";
import MenuComponent from "./MenuComponent";
import sendFBFile from "./sendFBFile";
import sendFBImage from "./sendFBImage";
import sendFBMessage from "./sendFBMessage";
import sendInstagramMessage from "./sendInstagramMessage";

export default function MessengerInboxPage() {
    const con = useConfirmation();
    const [refresh, setRefresh] = useState(false);
    const [quickReplyList, setQuickReplyList] = useState([]);
    const [quickReplyTag, setQuickReplyTag] = useState("");
    const [quickReplyText, setQuickReplyText] = useState("");
    const [searchText, setSearchText] = useState("");
    const [showTemplate, setShowTemplate] = useState(false);
    const [anchorElQuickReply, setAnchorElQuickReply] = useState(null);
    const [pastedImage, setPastedImage] = useState(null);
    const [chatRooms, setChatRooms] = useState([]);
    const [currentChatRoom, setCurrentChatRoom] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectChatRoom, setSelectChatRoom] = useState(true);
    const [message, setMessage] = useState("");
    const [chatRoomsLimit, setChatRoomsLimit] = useState(20);
    const [messagesLimit, setMessagesLimit] = useState(20);
    const [sort, setSort] = useState("date"); // Add sort state here
    const [openAccount, setOpenAccount] = useState(false);
    const [pages, setPages] = useState([]);
    const [currentPage, setCurrentPage] = useState({ id: "", name: "" });
    const [currentPageId, setCurrentPageId] = useState("");
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
    const [cursorPos, setCursorPos] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openQuickReply, setOpenQuickReply] = useState(false);
    const [editingQuickReply, setEditingQuickReply] = useState(null); // Track the current quick reply being edited
    const [selectedQuickReply, setSelectedQuickReply] = useState(null); // Track the currently selected quick reply
    const [searchQuickReply, setSearchQuickReply] = useState("");

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "whatsappQuickReply");

        // Query for user-created quick replies
        const userQuery = query(collectionRef, where("createdBy", "==", user.id));

        // Query for template quick replies
        const templateQuery = query(collectionRef, where("type", "==", "template"));

        // Function to handle query snapshot and set quick reply list
        const handleSnapshot = async (querySnapshot) => {
            let userQuickReplyList = mapSnapshot(querySnapshot);
            const templateSnapshot = await getDocs(templateQuery);
            let templateQuickReplyList = mapSnapshot(templateSnapshot);

            const combinedList = [...userQuickReplyList, ...templateQuickReplyList];
            combinedList?.sort((a, b) => b.date - a.date); // Sort by newest date first
            setQuickReplyList(combinedList);
        };

        // Subscribe to user query and fetch template query
        const unsubscribeUser = onSnapshot(userQuery, handleSnapshot);

        return () => {
            unsubscribeUser();
        }; // Cleanup subscription on unmount
    }, [user]);

    // Load pages from collection where admins includes user.email
    useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "pages");
        const q = query(collectionRef, where("admins", "array-contains", user.email), orderBy("date", "asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const pages = mapSnapshot(querySnapshot);
            setPages(pages);
        });
        return unsubscribe;
    }, [user]);

    // Load ChatRooms
    useEffect(() => {
        if (!currentPage?.id) {
            setCurrentChatRoom(null);
            return;
        }
        const collectionRef = collection(db, "fbMessages");
        const q = query(
            collectionRef,
            where("pageId", "==", currentPage.id),
            limit(chatRoomsLimit),
            orderBy(sort, "desc")
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const chatRooms = mapSnapshot(querySnapshot);
            console.log(chatRooms);
            setChatRooms(chatRooms);
        });
        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [currentPage, chatRoomsLimit, sort]); // Add chatRoomsLimit as a dependency

    useEffect(() => {
        if (!currentPageId) return;
        const docRef = doc(db, "pages", currentPageId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            const page = mapDocSnapshot(doc);
            setCurrentPage(page);
        });
        return unsubscribe;
    }, [currentPageId]);

    const handleUseQuickReply = () => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const textToInsert = quickReplyText;

        setMessage(
            (prevMessage) => prevMessage.slice(0, startPos) + textToInsert + prevMessage.slice(endPos)
        );

        const newCursorPosition = startPos + textToInsert.length;
        cursorPosRef.current = newCursorPosition; // Save the new cursor position to the ref

        handleCloseQuickReply();
    };

    const handleScroll = (e) => {
        // Check if the user is within 10 pixels of the bottom
        const nearBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10;

        if (nearBottom) {
            setChatRoomsLimit((prevLimit) => prevLimit + 20);
        }
    };

    const handleChangeQuickReplyTag = (e) => {
        setQuickReplyTag(e.target.value);
    };

    const handleChangeQuickReplyText = (e) => {
        setQuickReplyText(e.target.value);
    };

    const filteredQuickReplyList = showTemplate
        ? quickReplyList
        : quickReplyList.filter((item) => item.type !== "template");

    const handleMessagesScroll = (e) => {
        // Check if the user is within 10 pixels of the top
        console.log("currentChatRoom:", currentChatRoom);
        try {
            const nearTop = e.target.scrollHeight - e.target.clientHeight + e.target.scrollTop < 10;
            const nearBottom = e.target.scrollTop < 10;

            if (nearTop) {
                setMessagesLimit((prevLimit) => prevLimit + 20);
            }

            if (nearBottom) {
                const docRef = doc(db, "fbMessages", currentChatRoom.id);
                updateDoc(docRef, {
                    unread: 0,
                    unreadAI: 0,
                });
            }
        } catch (err) {
            console.log("handleMessagesScroll:", err);
        }
    };

    // Load Messages
    useEffect(() => {
        if (!currentChatRoom) {
            setMessages([]);
            return;
        }
        const collectionRef = collection(db, "fbMessages", currentChatRoom.id, "messages");
        const q = query(collectionRef, limit(messagesLimit), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = mapSnapshot(querySnapshot);
            setMessages(messages);
        });
        return unsubscribe;
    }, [currentChatRoom, messagesLimit]);

    const handleClickChatRoom = (chatRoom) => {
        console.log(chatRoom);
        setCurrentChatRoom(chatRoom);
        setSelectChatRoom(false);
    };

    useEffect(() => {
        if (inputRef.current && cursorPosRef.current !== null) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(cursorPosRef.current, cursorPosRef.current);
        }
    }, [message]);

    useEffect(() => {
        if (cursorPosRef.current !== null) {
            setCursorPos(cursorPosRef.current);
            cursorPosRef.current = null;
        }
    }, [message]);

    const handleDeleteQuickReply = async (quickReply) => {
        const response = await con("Are you sure you want to delete this quick reply?");
        if (!response) return;

        try {
            const docRef = doc(db, "whatsappQuickReply", quickReply.id);
            await deleteDoc(docRef);
            toast.success("Quick Reply Deleted Successfully");
        } catch (error) {
            console.log("Delete Quick Reply Error : ", error);
            toast.error("Failed to Delete Quick Reply");
        }
    };

    const windowSize = useWindowSize();

    const boxWidth = useMemo(() => {
        if (windowSize.width < 600) {
            // Mobile View
            if (selectChatRoom) {
                const leftWidth = "100%";
                const rightWidth = 0;
                return { leftWidth, rightWidth };
            } else {
                const leftWidth = 0;
                const rightWidth = "100%";
                return { leftWidth, rightWidth };
            }
        } else {
            // Desktop View
            const leftWidth = "30%";
            const rightWidth = "70%";
            return { leftWidth, rightWidth };
        }
    }, [windowSize, selectChatRoom]);

    const colors = useColors();

    const handleSendMessage = async () => {
        setLoading(true);
        let response;
        console.log("currentPage:", currentPage);
        const recipientId = currentChatRoom.id.split("-")[1];
        const pageId = currentChatRoom.id.split("-")[0];
        console.log("pageId:", pageId);
        console.log("image:", image);
        if (image?.type?.includes("application")) {
            // Using includes to check if type contains 'application'
            console.log("File type contains 'application'");
            if (currentPage?.fbPage === true) {
                response = await sendFBFile(currentChatRoom, image.downloadURL, currentPage.accessToken);
            }
            if (currentPage?.object === "instagram") {
                response = await sendInstagramMessage({
                    pageId,
                    recipientId,
                    fileUrl: image.downloadURL,
                    accessToken: currentPage.accessToken,
                    msgType: "file",
                });
            }
            if (response) {
                setImage(null);
            } else {
                toast.error("Error sending image");
            }
        }

        if (image?.type?.includes("image")) {
            if (currentPage?.fbPage === true) {
                response = await sendFBImage(currentChatRoom, image.downloadURL, currentPage.accessToken);
                if (response) console.log("Fb image sent");
            }
            if (currentPage?.object === "instagram") {
                response = await sendInstagramMessage({
                    pageId,
                    recipientId,
                    fileUrl: image.downloadURL,
                    accessToken: currentPage.accessToken,
                    msgType: "image",
                });
                if (response) console.log("Instagram image sent");
            }

            if (response) {
                setImage(null);
            } else {
                toast.error("Error sending image");
            }
        }

        if (message) {
            if (currentPage?.fbPage === true) {
                response = await sendFBMessage(currentChatRoom, message, currentPage.accessToken);
            }
            if (currentPage?.object === "instagram") {
                response = await sendInstagramMessage({
                    pageId,
                    recipientId,
                    messageText: message,
                    accessToken: currentPage.accessToken,
                    msgType: "text",
                });
            }
            if (response?.status) {
                setMessage("");
            } else {
                toast.custom(
                    <Paper variant="elevation" sx={{ width: ["90%", "50%"] }}>
                        <Box m={3}>
                            {response?.message?.includes("https://") ? (
                                <>
                                    <Typography color={red[500]} style={{ wordBreak: "break-word" }}>
                                        {response?.message?.split("https://")[0]}
                                    </Typography>
                                    <a
                                        href={"https://" + response?.message?.split("https://")[1]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: "red" }}
                                    >
                                        <Typography sx={{ wordBreak: "break-all" }}>
                                            {"https://" + response?.message?.split("https://")[1]}
                                        </Typography>
                                    </a>
                                </>
                            ) : (
                                <Typography color={red[500]} style={{ wordBreak: "break-word" }}>
                                    {response?.message}
                                </Typography>
                            )}
                        </Box>
                    </Paper>
                );
            }
        }

        setLoading(false);
    };

    const handleChangePage = (e) => {
        setCurrentChatRoom(null);
        setCurrentPageId(e.target.value);
    };

    const handleEmojiClick = (event) => {
        setEmojiAnchorEl(event.currentTarget);
    };

    const handleEmojiClose = () => {
        setEmojiAnchorEl(null);
    };

    const inputRef = useRef(null);
    const cursorPosRef = useRef(null);

    const handleClickEmoji = (e) => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;
        const emoji = `${e.emoji}`;

        setMessage((prevMessage) => prevMessage.slice(0, startPos) + emoji + prevMessage.slice(endPos));
        const newCursorPosition = startPos + emoji.length;
        cursorPosRef.current = newCursorPosition; // Save the new cursor position to the ref
    };

    const handleClickAssignDefaultFlow = async (currentFlowId, currentFlow) => {
        const docRef = doc(db, "pages", currentPage.id);
        await updateDoc(docRef, {
            defaultFlowId: currentFlowId,
            defaultFlowUserId: user.id,
            defaultFlowName: currentFlow.name,
        });
        toast.success("Default Flow assigned successfully");
        setOpenDialog(false);
    };

    const handleDeleteFlow = async () => {
        const confirmed = await con("Remove Flow", "Are you sure you want to remove the default flow?");
        if (confirmed) {
            const docRef = doc(db, "pages", currentPage.id);
            await updateDoc(docRef, {
                defaultFlowId: "",
                defaultFlowName: "",
                defaultFlowUserId: "",
            });
        }
    };

    const handleClickQuickReplyTag = (quickReply) => {
        setQuickReplyTag(quickReply.tag);
        setQuickReplyText(quickReply.desc);
        setEditingQuickReply(quickReply); // Set the current quick reply being edited
        setSelectedQuickReply(quickReply); // Set the selected quick reply
    };

    const handleClickQuickReply = (event) => {
        setAnchorElQuickReply(event);
        setOpenQuickReply(true);
    };

    const handleCloseQuickReply = () => {
        setAnchorElQuickReply(null);
        setOpenQuickReply(false);
    };

    const handleShowTemplateChange = (event) => {
        setShowTemplate(event.target.checked);
        handleClearQuickReply();
    };

    const handleClearQuickReply = () => {
        setSelectedQuickReply(null);
        setQuickReplyTag("");
        setQuickReplyText("");
        setEditingQuickReply(null);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        filterQuickReplies(event.target.value);
    };

    const handleClearSearchText = () => {
        setSearchText("");
        filterQuickReplies("");
    };

    const filterQuickReplies = (text) => {
        // Implement your filtering logic here based on the text
        setSearchQuickReply(
            quickReplyList.filter((item) => item.tag.toLowerCase()?.includes(text.toLowerCase()))
        );
    };

    const handleSetQuickReply = async () => {
        if (quickReplyTag === "" || quickReplyText === "") return;
        const response = await con("Are you sure you want to save this quick reply?");
        if (!response) return;

        try {
            if (editingQuickReply) {
                const docRef = doc(db, "whatsappQuickReply", editingQuickReply.id);

                // Update the existing document
                await updateDoc(docRef, {
                    tag: quickReplyTag,
                    desc: quickReplyText,
                });
            } else {
                // Create a new document if it does not exist
                await addDoc(collection(db, "whatsappQuickReply"), {
                    tag: quickReplyTag,
                    desc: quickReplyText,
                    createdBy: user.id,
                    date: new Date(),
                    type: "user",
                });
            }
            setQuickReplyTag("");
            setQuickReplyText("");
            setEditingQuickReply(null); // Reset editing state
            setSelectedQuickReply(null); // Reset selected state
            setRefresh(!refresh);
            toast.success("Quick Reply Set Successfully");
        } catch (error) {
            console.log("Set Quick Reply Error : ", error);
        }
    };

    const open = Boolean(emojiAnchorEl);
    const id = open ? "emoji-popover" : undefined;

    return (
        <>
            <Box display="flex" width="100%" height={"calc(100vh - 55px)"}>
                {/* Left Panel */}
                <Box
                    display={!boxWidth.leftWidth ? "none" : "flex"}
                    width={boxWidth.leftWidth}
                    flexDirection={"column"}
                    height={"100%"}
                >
                    <Box
                        p={"8px"}
                        width={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        gap="8px"
                        justifyContent={"space-between"}
                        sx={{ background: colors.topBar, borderRight: `1px solid ${colors.topBarLine}` }}
                    >
                        <Avatar />
                        {/* // Select drop down of page */}
                        <Select
                            value={currentPage?.id}
                            onChange={handleChangePage}
                            fullWidth
                            displayEmpty
                            sx={{
                                background: colors.searchBox,
                                borderRadius: "6px",
                                height: "35px",
                                padding: "0 8px 0 8px",
                                maxWidth: { xs: "200px", md: "150px" },
                            }}
                        >
                            <MenuItem disabled value="">
                                Select a page
                            </MenuItem>
                            {pages.map((page) => {
                                let IconComponent = null;
                                let color = "";

                                if (page.fbPage && page.fbPage === true) {
                                    IconComponent = FacebookIcon;
                                    color = "#1877F2"; // Facebook blue
                                } else if (page.object && page.object === "page") {
                                    IconComponent = FacebookIcon;
                                    color = "#1877F2"; // Facebook blue
                                } else if (page.object && page.object === "instagram") {
                                    IconComponent = InstagramIcon;
                                    color = "#E1306C"; // Instagram color
                                } else {
                                    console.log("Page object value is unknown.");
                                }

                                return (
                                    <MenuItem
                                        key={page.id}
                                        value={page.id}
                                        sx={{ display: "flex", alignItems: "center" }} // Horizontal alignment
                                        selected={currentPage?.id === page.id}
                                    >
                                        {IconComponent && (
                                            <IconComponent
                                                style={{
                                                    color: color,
                                                    marginRight: "8px",
                                                    verticalAlign: "middle", // Ensure the icon is vertically aligned
                                                }}
                                            />
                                        )}
                                        <span
                                            style={{
                                                verticalAlign: "middle", // Ensure the text is vertically aligned
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {page.name}
                                        </span>
                                    </MenuItem>
                                );
                            })}
                        </Select>

                        <AccountsDialog open={openAccount} setOpen={setOpenAccount} />
                    </Box>
                    <Box display="flex" width={"100%"} p="8px" gap="8px" alignItems="center">
                        <Input
                            disableUnderline
                            fullWidth
                            sx={{
                                background: colors.searchBox,
                                borderRadius: "6px",
                                height: "35px",
                                padding: "0 8px 0 8px",
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Button
                            onClick={() => setOpenDialog(true)}
                            disabled={!currentPage?.id}
                            size="small"
                            variant="contained"
                            sx={{ width: "130px" }}
                        >
                            Flow
                        </Button>
                        <Dialog
                            open={openDialog}
                            onClose={() => setOpenDialog(false)}
                            PaperProps={{
                                sx: {
                                    width: { xs: "99vw", md: "70vw" }, // Adjust breakpoints as needed
                                    height: "71vh",
                                    maxWidth: "100%", // Ensure the dialog doesn't exceed the screen width
                                },
                            }}
                        >
                            <FlowComponent user={user} onClose={handleClickAssignDefaultFlow} />
                        </Dialog>
                        <SortDialog sort={sort} setSort={setSort} />
                    </Box>

                    <Box
                        display="flex"
                        gap={1}
                        pb={1}
                        px={1}
                        alignItems={"center"}
                        sx={{ borderBottom: `1px solid ${colors.topBarLine}` }}
                    >
                        {currentPage?.defaultFlowName && (
                            <>
                                <Typography variant="body1">Default Flow:</Typography>
                                <Chip
                                    label={currentPage.defaultFlowName}
                                    size="small"
                                    color="primary"
                                    onDelete={handleDeleteFlow}
                                />
                            </>
                        )}
                    </Box>

                    <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection={"column"}
                        width="100%"
                        sx={{ overflowY: "auto" }}
                        onScroll={handleScroll} // Add the scroll handler here
                    >
                        {chatRooms.map((chatRoom) => (
                            <Box key={chatRoom.id} width={"100%"}>
                                <ChatRoomComponent chatRoom={chatRoom} handleClick={handleClickChatRoom} />
                            </Box>
                        ))}
                    </Box>
                </Box>

                {/* Right Panel */}
                <Box
                    width={boxWidth.rightWidth}
                    display={!boxWidth.rightWidth ? "none" : "flex"}
                    flexDirection={"column"}
                    alignContent={"center"}
                    height={"100%"}
                >
                    <Box
                        display={"flex"}
                        width={"100%"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        p={"8px"}
                        alignItems={"center"}
                        sx={{ background: colors.topBar }}
                    >
                        <Box display="flex" alignItems={"center"} gap="8px">
                            {boxWidth.leftWidth === 0 && (
                                <IconButton onClick={() => setSelectChatRoom(true)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <Avatar
                                sx={{ width: "45px", height: "45px" }}
                                src={currentChatRoom?.profilePic || ""}
                            />

                            <Typography variant="body1">{currentChatRoom?.name || ""}</Typography>
                            <Typography variant="body1">{currentChatRoom?.id.split("-")[1] || ""}</Typography>
                        </Box>
                        <Box display="flex" alignItems={"center"} gap="8px">
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                            <MenuComponent menuItems={["Contact Info", "Select Messages"]} />
                        </Box>
                    </Box>
                    <Box
                        flex={1}
                        display="flex"
                        flexDirection={"column-reverse"}
                        width="100%"
                        height="100%"
                        onScroll={handleMessagesScroll} // Add the scroll handler here
                        sx={{
                            backgroundImage: `url(${colors.whatsappBackground})`,
                            backgroundSize: "cover", // to cover the entire Box area
                            backgroundPosition: "center",
                            overflowY: "auto",
                        }}
                    >
                        {messages.map((message) => (
                            <ChatMessageComponent key={message.id} message={message} platform={"messenger"} />
                        ))}
                    </Box>
                    <Box
                        width={"100%"}
                        display="flex"
                        alignItems={"center"}
                        flexDirection={"row"}
                        sx={{ background: colors.topBar }}
                    >
                        <Box
                            p="4px"
                            width={"100%"}
                            alignItems={"center"}
                            display={currentChatRoom ? "flex" : "none"}
                        >
                            <IconButton onClick={handleEmojiClick}>
                                <InsertEmoticonIcon fontSize="large" />
                            </IconButton>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={emojiAnchorEl}
                                onClose={handleEmojiClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                            >
                                <EmojiPicker onEmojiClick={handleClickEmoji} />
                            </Popover>
                            <AddItemsMenu
                                attachment={image}
                                setAttachment={setImage}
                                setOpenQuickReply={handleClickQuickReply}
                            />
                            {image && (
                                <Box display="flex" alignItems="center">
                                    <ImageIndicator
                                        image={image}
                                        setImage={image ? setImage : setPastedImage}
                                    />
                                </Box>
                            )}
                            <Input
                                fullWidth
                                disabled={!currentChatRoom || loading}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault(); // Prevent the default behavior of adding a new line
                                        handleSendMessage(); // Call your send message function
                                    }
                                }}
                                disableUnderline
                                multiline
                                inputRef={inputRef}
                                maxRows={6}
                                sx={{
                                    background: "white",
                                    borderRadius: "6px",
                                    padding: "0px 8px 0 8px",
                                    minHeight: "40px",
                                    backgroundColor: colors.textInput,
                                }}
                            />
                            <IconButton
                                onClick={handleSendMessage}
                                disabled={(!currentChatRoom && !image) || loading}
                            >
                                {loading ? <CircularProgress size={24} /> : <SendIcon fontSize="large" />}
                            </IconButton>
                            <IconButton>
                                <ScheduleIcon color="#54656f" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Menu
                anchorEl={anchorElQuickReply}
                open={Boolean(anchorElQuickReply)}
                onClose={handleCloseQuickReply}
                sx={{
                    "& .MuiPaper-root": {
                        width: "80%",
                        maxWidth: "350px",
                    },
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "8px 0",
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox checked={showTemplate} onChange={handleShowTemplateChange} />}
                            label="Template"
                        />
                        <TextField
                            label="Search"
                            value={searchText}
                            onChange={handleSearchTextChange}
                            InputProps={{
                                endAdornment: searchText ? (
                                    <IconButton onClick={handleClearSearchText}>
                                        <SearchOffIcon />
                                    </IconButton>
                                ) : null,
                            }}
                            sx={{ marginLeft: 2 }}
                        />
                    </Box>
                    <Box
                        sx={{
                            maxHeight: "150px", // Adjust the height as needed
                            overflowY: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                            // gap: 1, // Adjust the gap as needed
                            padding: 0.5, // Add padding if needed
                        }}
                    >
                        {filteredQuickReplyList?.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.tag}
                                color={
                                    selectedQuickReply && selectedQuickReply.id === item.id
                                        ? "success"
                                        : "default"
                                }
                                sx={{ marginBottom: 1, marginLeft: 1 }}
                                onClick={() => handleClickQuickReplyTag(item)}
                                onDelete={() => item.type !== "template" && handleDeleteQuickReply(item)} // Disable delete if type is template
                                deleteIcon={item.type === "template" ? <span /> : undefined} // Hide delete icon if type is template
                            />
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            width: "100%",
                            padding: "4px",
                        }}
                    >
                        <TextField
                            label="Quick Reply Tag"
                            fullWidth
                            sx={{ marginBottom: "10px" }}
                            value={quickReplyTag}
                            onChange={handleChangeQuickReplyTag}
                            disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                        />
                        <TextField
                            id="quick-reply-text"
                            label="Quick Reply Text"
                            multiline
                            fullWidth
                            minRows={4}
                            maxRows={4}
                            value={quickReplyText}
                            onChange={handleChangeQuickReplyText}
                            sx={{ marginBottom: "10px" }}
                            disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                        />
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                            <Button
                                variant="contained"
                                onClick={handleSetQuickReply}
                                sx={{ flex: 1 }}
                                disabled={selectedQuickReply && selectedQuickReply.type === "template"} // Disable if type is template
                            >
                                {editingQuickReply ? "Save" : "Add"}
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                onClick={handleClearQuickReply}
                                sx={{ flex: 1 }}
                            >
                                Clear
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleUseQuickReply}
                                sx={{ flex: 1 }}
                                disabled={!selectedQuickReply} // Disable if no quick reply is selected
                            >
                                Use This
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Menu>
        </>
    );
}
