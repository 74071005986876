import React, { useState, useEffect } from 'react'
import { collection, query, where, getDocs, updateDoc, doc, orderBy } from "firebase/firestore";
import { db } from '../firebase/firebase-utils';
import * as XLSX from "xlsx";
import { useConfirmation } from '../context-utils/ConfirmationContext';
//update run on 10-9-24 11.30pm

const Waynetest = () => {
  const [errorTasks, setErrorTasks] = useState([]);
  const confirm = useConfirmation();

  useEffect(() => {
    fetchErrorTasks();
  }, []);

  const updateFunctionName = async () => {
      const isConfirmed = await confirm(
          "Are you sure you want to update all records from sendFaceBookMessage to sendWhatsAppMessage?"
      );

      if (isConfirmed) {
        console.log('confirm.');
        //   try {
        //       console.log("Update start");
        //       for (const task of errorTasks) {
        //           const taskRef = doc(db, "scheduledTasks", task.id);
        //           await updateDoc(taskRef, {
        //               functionName: "sendWhatsAppMessage",
        //           });
        //       }
        //       console.log("Update complete");
        //       fetchErrorTasks(); // Refresh the list after updating
        //   } catch (error) {
        //       console.error("Error updating function name:", error);
        //   }
      }
  };

  const exportToExcel = () => {
      // Create a new array with formatted data
      const formattedTasks = errorTasks.map(task => ({
          ...task,
          scheduledTime: formatTimestamp(task.scheduledTime),
          paramsMessage: task.params.message // Added to include params.message in the export
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedTasks);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Scheduled Tasks");
      XLSX.writeFile(workbook, "scheduled_tasks.xlsx");
  };

  const fetchErrorTasks = async () => {
    try {
        console.log("fetch start");
      const tasksRef = collection(db, 'scheduledTasks');
      const q = query(
          tasksRef,
          // where("status", "==", "scheduled"),
          //   where("createdBy", "==", "nurul.iptvbox@gmail.com"),
          // where("functionName", "==", "sendFaceBookMessage")
          where("to", "==", "60179284320@s.whatsapp.net"),
          orderBy("scheduledTime", "desc")
      );
      const querySnapshot = await getDocs(q);
      const tasks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setErrorTasks(tasks);
      console.log("fetch end");
    } catch (error) {
      console.error('Error fetching error tasks:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
      if (timestamp && timestamp.seconds) {
          return new Date(timestamp.seconds * 1000).toLocaleString();
      }
      return "N/A";
  };

  return (
      <div>
          <h2>Scheduled Tasks {errorTasks.length}</h2>{" "}
          <button onClick={exportToExcel}>Export to Excel</button>
          <button onClick={updateFunctionName}>Update to WhatsApp</button>
          {errorTasks.map((task) => (
              <div key={task.id} style={{ border: "1px solid #ccc", margin: "10px", padding: "10px" }}>
                  <p>Block ID: {task.blockId}</p>
                  <p>Flow ID: {task.flowId}</p>
                  <p>Function Name: {task.functionName}</p>
                  <p>To: {task.to}</p>
                  <p>Status: {task.status}</p>
                  <p>Message: {task.message}</p>
                  <p>Params Message: {task.params.message}</p>
                  <p>Scheduled TIme : {formatTimestamp(task.scheduledTime)}</p>
                  <p></p>
              </div>
          ))}
      </div>
  );
}

export default Waynetest